import React, { useEffect } from "react";
import "./RedirectPage.css";

const Nrib = () => {
  useEffect(() => {
    // Redirect after 3 seconds
    const timer = setTimeout(() => {
      window.location.href = "https://telugutopdeals.tech/doctor-brides";
    }, 3000);

    // Cleanup timer if component unmounts
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="redirect-container">
      <h1 className="redirect-heading">Redirecting...</h1>
      <p className="redirect-message">
        You will be redirected to our <strong>Doctor Brides</strong> page in a few seconds.
      </p>
      <a
        href="https://telugutopdeals.tech/doctor-brides"
        className="redirect-link"
      >
        Click here if you are not redirected automatically
      </a>
    </div>
  );
};

export default Nrib;
