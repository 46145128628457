// src/components/Footer.js

import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import "./Styles/Footer.css"; // Ensure this path is correct based on your project structure

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* About Section */}
        <div className="footer-section about">
          <h2>Karanki Matrimony</h2>
          <p>Connecting hearts and souls. Find your perfect match with Karanki Matrimony.</p>
        </div>

        {/* Quick Links Section */}
        <div className="footer-section links">
          <h3>Quick Links</h3>
          <ul className="footer-links">
          <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/termsconditions">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <Link to="/explore">Get Profiles</Link>
            </li>
          </ul>
        </div>

        {/* Contact Information Section */}
        <div className="footer-section contact">
          <h3>Contact Us</h3>
          <p>Email: <a href="mailto:karankimatrimony2020@gmail.com">karankimatrimony2020@gmail.com</a></p>
          <p>Address: Kukatpally, Hyderabad, Telangana, 500072.</p>

        </div>

        {/* Social Media Section */}
        <div className="footer-section social-media">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61560882598842" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FaFacebookF />
            </a>
           
            <a href="https://instagram.com/karankimatrimony" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram />
            </a>
            <a href="https://wa.me/919912349925" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
    <FaWhatsapp />
</a>

          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Karanki Matrimony. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
