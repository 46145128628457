import React from 'react';
import { FaDownload } from 'react-icons/fa'; // Importing the download icon
import './Styles/Explore.css'; // Import the CSS file

const Explore = () => {
  const handleRedirect = () => {
    // Redirect to the PDF file
    window.location.href = 'https://telugutopdeals.tech/Matches-karanki%20matrimony'; // Replace with the actual URL of the PDF
  };

  return (
    <div className="explore-container">
      <h1 className="explore-heading">Karanki Matrimony</h1>
      <p className="explore-description">
        Click the button below to access PDF ! Find your perfect match...
      </p>
      <button onClick={handleRedirect} className="explore-button">
        <FaDownload /> Get Access to the PDF
      </button>
      <footer className="explore-footer">
        <p>&copy; {new Date().getFullYear()} Karanki Matrimony. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Explore;
